import Api from '../../../common/services/api';

import { NETWORK_RECEIVE_USER_COUNTS, NETWORK_RECEIVE_USERS } from './index';

export default (offset = 0, filter = {}, limit = 30) => async (dispatch, getState) => {
  const { organisation: { selected }, network: { selected: selectedNetwork } } = getState();

  const query = Api.utils.toQuery({
    offset,
    limit,
    q: filter.query,
    select: filter.status,
    sort_by: filter.sortBy || 'first_name',
    can_chat: filter.canChat,
    ...(!filter.status && { function_ids: filter.functionIds }),
    network_ids: filter.networkIds ? filter.networkIds : [selectedNetwork],
  });

  const request = await Api.get(`/v2/organisations/${selected.id}/users?${query}`);
  const { data: items, meta: { pagination } } = request;
  const { network: { users: { counts } } } = getState();

  dispatch({
    type: NETWORK_RECEIVE_USERS,
    items,
    pagination,
    strategy: pagination.offset > 0 ? 'append' : null,
  });

  if (!filter.status) {
    dispatch({
      type: NETWORK_RECEIVE_USER_COUNTS,
      counts: {
        ...counts,
        total: pagination.total_count
      },
    });
  }
  return request;
};

export const fetchNetworkUsers = (offset = 0, filter = {}, limit = 30) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const query = Api.utils.toQuery({
    offset,
    limit,
    q: filter.query,
    status: filter.status,
    sort_by: filter.sortBy,
    can_chat: filter.canChat,
  });

  return Api.get(`/v2/networks/${selected}/users?${query}`);
};

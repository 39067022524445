import * as React from 'react';
import { Trans } from 'react-i18next';

import { useAppSelector } from '@common/hooks/redux';
import { useHistory } from 'react-router-dom';
import { ShortcutTypes } from '@modules/admin/definitions';

import { FileViewer } from '@common/components/file-viewer';

import * as AnalyticsService from '../../../../common/services/analytics';
import { EEventNames } from '../../../../../client/analytics';
import Permission from '../../../../common/components/permission';
import List from '../../../../common/components/list';
import { EPermissions } from '../../../../common/definitions';
import { transformUrl } from '../../../../common/utils/url';
import { Shortcut } from '@modules/social/types/objects';
import Api from '@common/services/api';
import { ResponseDocument, ResponseFile } from '@modules/documents/types';
import { useState } from 'react';
import Spinner from '@common/components/spinner';

type ShortcutItemProps = {
  item: Shortcut,
};

const ShortcutItemComponent = React.memo<ShortcutItemProps>(({ item }: ShortcutItemProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const [preview, setPreview] = useState<ResponseFile[]>([]);

  const selectedNetwork = useAppSelector((store) => store.network.selected);
  const organisation = useAppSelector((store) => store.organisation.selected);

  const handleClick = React.useCallback(async () => {

    switch (true) {
      case item.type === ShortcutTypes.FORM:
        history.push(`/networks/${selectedNetwork}/forms/${item.data.target_id}/submission`);
        break;
      case item.type === ShortcutTypes.DOCUMENT:
        setLoading(true);
        const response = await Api.post<{ data: ResponseDocument }>(
          `/v1/organisations/${organisation.id}/documents/${item.data.target_id}/open`
        );
        setLoading(false);
        const document = response.data;

        if ('attachment' in document && document.attachment) {
          setPreview([document]);
        } else if ('page' in document && document.page) {
          history.push(`/networks/${selectedNetwork}/documents/page/${item.data.target_id}`);
        } else {
          history.push(`/networks/${selectedNetwork}/documents/${item.data.target_id}`);
        }
        break;

      case item.type === ShortcutTypes.EXTERNAL_LINK:
      default:
        window.open(transformUrl(item.data.web?.url || ''), '_blank');
        break;
    }
  }, [item, history, selectedNetwork]);

  const { url, color } = item.icon;

  return (
    <>
      <a
        className="Shortcut"
        onClick={handleClick}
        rel="noreferrer"
      >
        <div>
          <div className="Shortcut__Icon" style={{ backgroundColor: color, position: 'relative' }}>
            {(loading) ?
              (
                <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center">
                  <Spinner />
                </div>
              ) : (
                <img src={url} alt={item.name} />
              )}
          </div>
          <div className="Shortcut__Name">{item.name}</div>
        </div>
      </a>
      {preview.length > 0 && (
        <FileViewer
          isVisible={preview.length > 0}
          setIsVisible={() => {
            setPreview([]);
          }}
          content={preview}
          onShow={() => { }}
          onPageChange={() => { }}
        />
      )}
    </>
  );
});

interface Props {
  shortcuts: Shortcut[],
  onView: () => unknown,
}

export const ShortcutsWidget = ({ shortcuts, onView }: Props) => (
  <div className="balloon">
    <h5 className="balloon__title balloon__title--no-margin">
      <span>
        <Trans i18nKey="social:shortcuts" values={{ count: shortcuts.length }} components={[<small />]} />
      </span>
      <Permission name={EPermissions.ORGANISATION_ORGANISATIONS_UPDATE}>
        <div className="pull-right">
          <a onClick={onView}><Trans i18nKey="social:view_shortcuts" /></a>
        </div>
      </Permission>
    </h5>
    <List
      items={shortcuts}
      renderRow={ShortcutItemComponent}
      rowProps={{
        onOpen: () => AnalyticsService.track(EEventNames.OPENED_SHORTCUT),
      }}
    />
  </div>
);

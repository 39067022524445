import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Switch, Route } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { change as changeAction } from 'redux-form';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';

import { TrackComponent, EEventNames } from '../../../../../client/analytics';
import IntervalService from '../../../../common/services/interval';
import * as AnalyticsService from '../../../../common/services/analytics';
import AsyncList from '../../../../common/components/list/async';
import Container from '../../../../common/components/container';
import { Button } from '../../../../common/components/button';
import Spinner from '../../../../common/components/spinner';
import Card from '../../../../common/components/card';
import SearchBar from '../../../../common/components/search-bar';
import Icon from '../../../../common/components/icon';
import Modal from '../../../../common/components/modal';
import Placeholder from '../../../../common/components/placeholder';
import Confirm from '../../../../common/components/confirm-button';
import BirthdaysList from '../../../network/components/birthdays-list';
import PostMessage from '../../components/post-message';
import FeedMessageCard from '../../components/feed-item/message';
import FeedBirthdayCard from '../../components/feed-item/birthday';
import Channels from '../../components/channels';
import TimelineHeader from '../../components/timeline-header';
import Document from '../../components/document';
import Feed from '../../components/feed';
import { BirthdaysWidget } from '../../components/birthdays-widget';
import { EventsWidget } from '../../components/events-widget';
import { ShortcutsWidget } from '../../components/shortcuts-widget';
import { MembersWidget } from '../../components/members-widget';
import FeedSectionHeader, { FeedSectionHeaderOrder } from '../../components/feed-section-header';
import * as userSelector from '../../../core/selectors/logged-user';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as networkSelector from '../../../network/selectors/network';
import * as timelineSelector from '../../selectors/timeline';
import * as scheduledSelector from '../../selectors/scheduled';
import * as eventsSelector from '../../../events/selector';
import { TopBarContentContext } from '../../../network/top-bar-content-context';
import { ETimelineTypes, EFeedFilterTypes, EFeedSortTypes } from '../../definitions';
import type { StoreState } from '../../../../common/types/store';
import Api from '@common/services/api';
import { FetchShortcutsApiResponse, Shortcut } from '@modules/social/types/objects';
import NewSurveyBadge from './new-survey-badge';

require('../../styles.scss');

// Map conversation type to meta data for Mixpanel
const channelTypeToMeta = {
  [ETimelineTypes.FEED]: 'User Feed',
  [ETimelineTypes.NETWORK]: 'Community',
  [ETimelineTypes.TEAM]: 'Communication Group',
  [ETimelineTypes.ORGANISATION]: 'Organization',
  [ETimelineTypes.CHANNEL]: 'Organization Group',
};

const feedSortingToMeta = {
  [EFeedSortTypes.LAST_ACTIVITY]: 'Last Activity',
  [EFeedSortTypes.LAST_CREATED]: 'Recent Messages',
};

type Params = {
  networkId: string;
  query?: string;
  type?: string;
  id?: string;
};

const mapStateToProps = (state: StoreState, props: { match: { params: Params } }) => ({
  loggedUser: userSelector.selected(state),
  organisation: organisationSelector.selected(state),
  network: networkSelector.selected(state),
  networkIsInitializing: networkSelector.isInitializing(state),

  feed: timelineSelector.feed(state),
  newFeedItems: timelineSelector.newMessages(state, props.match.params),
  channel: timelineSelector.selected(state, props.match.params) as any,
  colleagues: timelineSelector.colleagues(state),
  birthdaysToday: timelineSelector.birthdaysForDate(state),
  birthdayMessageToday: timelineSelector.birthdayMessageForDate(state),
  importantMessages: timelineSelector.important(state),
  unreadImportantMessages: timelineSelector.unread(state),
  feedOrder: timelineSelector.getOrder(state),

  teams: userSelector.teams(state),
  channels: userSelector.channels(state),
  employees: networkSelector.users(state),
  scheduledMessages: scheduledSelector.messages(state),
  scheduledCount: scheduledSelector.count(state),
  events: eventsSelector.available(state),
});

/* eslint-disable global-require */
const mapDispatchToProps = {
  // @ts-expect-error
  getState: () => (_, getState) => getState(),
  change: changeAction,
  fetchTimeline: require('../../actions/fetch-timeline').default,
  receiveMessages: require('../../actions/fetch-timeline').receiveMessages,
  receiveImportantMessages: require('../../actions/fetch-timeline').receiveImportantMessages,
  receiveUnreadMessages: require('../../actions/fetch-timeline').receiveUnreadMessages,
  processQueue: require('../../actions/seen').processQueue,
  fetchCongratulationsForDate: require('../../actions/fetch-congratulations-for-date').default,
  fetchBirthdaysForDate: require('../../../network/actions/fetch-birthdays-for-date').default,
  fetchBirthdays: require('../../../network/actions/fetch-birthdays').default,
  fetchScheduledMessages: require('../../actions/fetch-scheduled-messages').default,
  fetchEvents: require('../../actions').fetchEvents,
  fetchUsers: require('../../../network/actions/fetch-users').default,
  deleteMessage: require('../../actions/delete-message').default,
  fetchFiles: require('../../actions').fetchFiles,
  changeFeedOrder: require('../../actions').changeFeedOrder,
  hideChannel: require('../../actions').hideChannel,
  muteChannel: require('../../actions').muteChannel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type RouteProps = RouteComponentProps<Params>;
type ConnectorProps = ConnectedProps<typeof connector>;

type Props = RouteProps & ConnectorProps & {
  t: (key: string, values?: Record<string, unknown>) => string;
};

type State = {
  confirm: {
    title: string;
    description: string | null;
    confirmText: string;
    cancelText: string;
    onConfirm: () => unknown;
    onCancel: () => unknown;
  } | null;
  showBirthdays: boolean;
  lastCursor: string | null;
  shortcuts: Shortcut[];
};

class Social extends Component<Props, State> {
  static props: Props;

  listRef: { current: typeof AsyncList | undefined };
  formRef: { current: typeof PostMessage | undefined };
  wrapperElement: HTMLElement | undefined;
  handleShowIntercom: () => void;
  handleResetShowBirthdays: () => void;
  handleViewBirthdays: () => void;
  handleViewShortcuts: () => void;
  handleOpenEvent: (id: string) => any;
  handleViewEvents: () => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      confirm: null,
      showBirthdays: false,
      lastCursor: null,
      shortcuts: [],
    };

    // @ts-expect-error
    this.formRef = React.createRef();
    // @ts-expect-error
    this.listRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSelectChannel = this.handleSelectChannel.bind(this);
    this.handleViewBirthdays = () => this.setState({ showBirthdays: true });
    this.handleViewShortcuts = () => props.history.push('/admin/settings/shortcuts');
    this.handleOpenEvent = (eventId: string) => props.history.push(`/networks/${props.network.id}/events/${eventId}`);
    this.handleViewEvents = () => props.history.push(`/networks/${props.network.id}/events`);
    this.handleShowIntercom = () => window.Intercom && window.Intercom('show'); // eslint-disable-line new-cap
    this.handleResetShowBirthdays = () => this.setState({ showBirthdays: false });
  }

  componentDidMount() {
    const { colleagues, fetchBirthdaysForDate, fetchEvents } = this.props;

    fetchBirthdaysForDate(moment().format('YYYY-MM-DD'));
    fetchEvents();
    this.fetchShortcuts();

    if (!colleagues.length) {
      this.props.fetchUsers();
    }

    if (typeof document !== 'undefined') {
      const wrapper = document.getElementsByClassName('Content__Wrapper')[0];

      if (wrapper) {
        // @ts-expect-error
        this.wrapperElement = wrapper;
        // @ts-expect-error
        this.wrapperElement.addEventListener('scroll', this.handleScroll);
      }
    }

    IntervalService.add('process', this.props.processQueue, 10000);
  }

  componentWillUnmount() {
    this.props.processQueue();

    IntervalService.clear('process');
    document.removeEventListener('scroll', this.handleScroll);
  }

  async fetchShortcuts() {
    const { data } = await Api.get<FetchShortcutsApiResponse>(`/v4/organisations/${this.props.organisation.id}/shortcuts`);
    this.setState({ shortcuts: data });
  }

  handleScroll() {
    const el = this.wrapperElement;

    if (!el) return;

    const scrollPosition = el.scrollTop;
    const scrollHeight = el.scrollHeight - el.clientHeight;
    const isNearBottom = scrollPosition > scrollHeight * 0.75;

    // If reference is set, scroll is near bottom of the screen
    if (isNearBottom && this.listRef.current) {
      // @ts-expect-error
      const { nextCursor } = this.listRef.current.state.dataManager.get();

      // Check if the nextCursor isn't already fetching
      if (nextCursor !== null && nextCursor !== this.state.lastCursor) {
        // @ts-expect-error
        this.state.lastCursor = nextCursor;
        // @ts-expect-error
        if (this.listRef.current) this.listRef.current.handleFetchMoreData();
      }
    }
  }

  handleSelectChannel(type: string, id: string) {
    const {
      history, match: { params }, channel, getState, change, t,
    } = this.props;
    const state = getState();

    // Redirect to correct url
    const handleSelect = () => {
      if (type === ETimelineTypes.FEED) {
        history.push(`/networks/${params.networkId}/feed`);
      } else if (type === ETimelineTypes.NETWORK) {
        history.push(`/networks/${params.networkId}/messages/network`);
      } else if (type === ETimelineTypes.ORGANISATION) {
        history.push(`/networks/${params.networkId}/messages/organisation`);
      } else {
        history.push(`/networks/${params.networkId}/messages/${type}/${id}`);
      }
    };

    // If the user is creating a message while switching to a different channel
    if (
      state.form['post-message'] && state.form['post-message'].values.text
      && `${type}-${id}` !== `${channel.type}-${channel.id}`
    ) {
      this.setState({
        confirm: {
          title: t('social:confirm_complete_message_title'),
          description: t('social:confirm_complete_message_description'),
          confirmText: t('social:confirm_complete_message_confirm_text'),
          cancelText: t('social:confirm_complete_message_cancel_text'),
          onConfirm: () => {
            this.setState({ confirm: null });
            // @ts-expect-error
            if (this.formRef.current) this.formRef.current.handleFocusInput();
          },
          onCancel: () => {
            handleSelect();
            change('post-message', 'text', '');

            this.setState({
              confirm: null,
            });
          },
        },
      });
    } else {
      handleSelect();
    }
  }

  render() {
    const { confirm, showBirthdays, shortcuts } = this.state;
    const { history, match, t } = this.props;
    const {
      organisation,
      network,
      loggedUser,
      channel,
      teams,
      channels,
      events,
      scheduledCount,
      birthdaysToday,
      birthdayMessageToday,
      feed,
      newFeedItems,
      scheduledMessages,
      importantMessages,
      unreadImportantMessages,
      feedOrder,
      hideChannel,
      muteChannel,
      networkIsInitializing
    } = this.props;

    const { networkId } = match.params;

    const myTimelinePath = `/networks/${networkId}/feed`;

    if (!channel || !channel.parent) {
      history.push(myTimelinePath);

      return null;
    }

    const today = moment();
    const birthday = moment(loggedUser.date_of_birth);
    const isBirthdayToday = today.date() === birthday.date() && today.month() === birthday.month();

    return (
      <Container name="Social">
        {confirm && <Confirm {...confirm} />}
        <Modal
          show={showBirthdays}
          onEnter={() => AnalyticsService.track(EEventNames.VIEWED_BIRTHDAY_OVERVIEW)}
          onClose={this.handleResetShowBirthdays}
          content={(
            // @ts-expect-error
            <BirthdaysList loggedUser={loggedUser} />
          )}
        />
        <Container.Content horizontal>
          <Container.SideBar>
            <Channels
              network={network}
              organisation={organisation}
              loggedUser={loggedUser}
              teams={teams}
              channels={channels}
              onSelectChannel={this.handleSelectChannel}
              onHideChannel={hideChannel}
              onMuteChannel={muteChannel}
              selected={channel}
            />
          </Container.SideBar>

          <Container.Column>
            <TopBarContentContext.Consumer>
              {(ref) => ref && ReactDOM.createPortal(
                <SearchBar
                  key={`${channel.type}-${channel.id}-${match.params.query ? 'q' : ''}`}
                  defaultValue={match.params.query}
                  filter={false}
                  placeholder={t('social:search_bar_placeholder', {
                    channelName: (channel.parent && channel.parent.name) || t('social:channels_my_timeline').toLowerCase(),
                  })}
                  onSearch={(query) => {
                    const rootUrl = match.url.split('/search')[0];

                    return history.push(query ? `${rootUrl}/search/${query}` : rootUrl);
                  }}
                />,
                // @ts-expect-error
                ref,
              )}
            </TopBarContentContext.Consumer>

            <Switch>
              <Route
                path="/networks/:networkId/*/search/:query"
                render={({ match: { params } }) => (
                  <TrackComponent
                    key={`filter-${channel.type}-${channel.id}`}
                    eventName={EEventNames.SEARCHED_MESSAGES}
                    properties={{ type: channelTypeToMeta[channel.type] }}
                  >
                    <Feed
                      listRef={this.listRef}
                      data={{
                        useCursor: true,
                        onFetch: this.props.fetchTimeline,
                        filter: {
                          type: channel.type,
                          id: channel.id,
                          q: params.query,
                        },
                        onPostFetch: this.props.receiveMessages,
                      }}
                      placeholder={(
                        <Placeholder
                          size="small"
                          title={t('social:feed_search_placeholder_title')}
                          description={t('social:feed_search_placeholder_description', { query: params.query })}
                          image="/static/images/placeholders/timeline.svg"
                        />
                      )}
                    />
                  </TrackComponent>
                )}
              />
              <Route>
                <TimelineHeader
                  organisation={organisation}
                  network={network}
                  channel={channel}
                  baseUrl={match.url}
                />
                <Switch>
                  <Route
                    path="/networks/:networkId/*/files"
                    component={() => (
                      <TrackComponent
                        key={`files-${channel.type}-${channel.id}`}
                        eventName={EEventNames.VIEWED_FILES}
                        properties={{ type: channelTypeToMeta[channel.type] }}
                      >
                        <AsyncList
                          data={{
                            useCursor: true,
                            onFetch: this.props.fetchFiles,
                            filter: {
                              type: channel.type,
                              id: channel.id,
                            },
                          }}
                          rowProps={{
                            onOpenMessage: (messageId: string) => history.push(`/messages/${messageId}`),
                          }}
                          containerClassName="Social__Files"
                          renderRow={Document}
                          placeholder={(
                            <Placeholder
                              size="small"
                              title={t('social:feed_files_placeholder_title')}
                              image="/static/images/placeholders/timeline.svg"
                            />
                          )}
                          ShowMoreComponent={({ onShowMore, isFetching }) => (isFetching
                            ? <Spinner centered size="large" />
                            : (
                              <div className="Feed__ShowMore" onClick={onShowMore}>
                                <Trans i18nKey="social:feed_files_load_more" />
                              </div>
                            )
                          )}
                        />
                      </TrackComponent>
                    )}
                  />
                  <Route
                    path="/networks/:networkId/*/filter/:filter"
                    render={({ match: { params: { filter } } }) => (
                      <TrackComponent
                        key={`filter-${channel.type}-${channel.id}`}
                        eventName={EEventNames.VIEWED_IMPORTANT_MESSAGES}
                        properties={{ type: channelTypeToMeta[channel.type] }}
                      >
                        <Feed
                          listRef={this.listRef}
                          items={importantMessages}
                          data={{
                            useCursor: true,
                            onFetch: this.props.fetchTimeline,
                            filter: {
                              filter,
                              type: channel.type,
                              id: channel.id,
                            },
                            onPostFetch: this.props.receiveImportantMessages,
                          }}
                          placeholder={(
                            <Placeholder
                              size="small"
                              title={t('social:feed_filter_placeholder_title', { context: filter })}
                              image="/static/images/placeholders/timeline.svg"
                            />
                          )}
                        />
                      </TrackComponent>
                    )}
                  />
                  <Route>
                    <TrackComponent
                      key={`timeline-${channel.type}-${channel.id}`}
                      eventName={EEventNames.VIEWED_FEED}
                      properties={{ type: channelTypeToMeta[channel.type] }}
                    >
                      <>
                        {channel.can_create && (
                          <PostMessage
                            // @ts-expect-error
                            ref={this.formRef}
                            // @ts-expect-error
                            channels={channels.filter((item) => item.settings.can_post)}
                            onSelectChannel={this.handleSelectChannel}
                            {...R.pick(['organisation', 'network', 'loggedUser', 'teams', 'channel'], this.props)}
                          />
                        )}
                        {scheduledCount > 0 && (
                          <Modal
                            list
                            className="modal--color-grey"
                            onEnter={() => AnalyticsService.track(EEventNames.VIEWED_SCHEDULED_MESSAGES)}
                            content={(
                              <Feed
                                disableScrollListener
                                items={scheduledMessages}
                                data={{
                                  useCursor: true,
                                  onFetch: this.props.fetchScheduledMessages,
                                }}
                                renderRow={FeedMessageCard}
                                rowProps={{
                                  disableActions: true,
                                  onDelete: (id: string) => this.props.deleteMessage(id, true),
                                  onDeleteComment: R.F,
                                }}
                                // @ts-expect-error
                                ShowMoreComponent={({ onShowMore, isFetching }) => (
                                  <Button onClick={onShowMore} type="inverted-primary" isLoading={isFetching}>
                                    <Trans i18nKey="social:scheduled_load_more" />
                                  </Button>
                                )}
                              />
                            )}
                          >
                            <Card containerClassName="ScheduleMessages">
                              <Card.Content>
                                <Icon type="access_time" />
                                {' '}
                                <Trans i18nKey="social:feed_planned_messages" values={{ count: scheduledCount }} />
                              </Card.Content>
                            </Card>
                          </Modal>
                        )}

                        <Feed
                          hideSpinner
                          containerClassName="ImportantMessages"
                          items={unreadImportantMessages[0] ? [unreadImportantMessages[0]] : []}
                          data={{
                            useCursor: true,
                            onFetch: this.props.fetchTimeline,
                            limit: 25,
                            filter: {
                              type: channel.type,
                              id: channel.id,
                              filter: EFeedFilterTypes.IMPORTANT_UNREAD,
                            },
                            onPostFetch: this.props.receiveUnreadMessages,
                          }}
                          rowProps={{
                            shouldHideParent: channel ? channel.type !== ETimelineTypes.FEED : false,
                          }}
                          header={() => (
                            <FeedSectionHeader
                              icon={<Icon type="star" className="FeedSectionHeader__Important" />}
                              title={t('social:feed_unread_important')}
                              count={unreadImportantMessages.length}
                              action={(
                                <Link to={`${match.url}/filter/important`}>
                                  <Trans i18nKey="social:feed_unread_important_see_all" />
                                </Link>
                              )}
                            />
                          )}
                          placeholder={false}
                        />

                        <Route path={myTimelinePath}>
                          <NewSurveyBadge
                            networkId={networkId}
                            orgId={organisation.id}
                            // this initialization check is a fix for the issue
                            // outlined in the first point of this comment
                            // https://github.com/FlexAppeal/dashboard/pull/1872#pullrequestreview-2027952384
                            linkDisabled={networkIsInitializing}
                          />
                        </Route>

                        <FeedSectionHeaderOrder
                          active={feedOrder}
                          onChange={(order) => {
                            this.props.changeFeedOrder(order);

                            AnalyticsService.track(EEventNames.SORTED_TIMELINE, {
                              sorting: feedSortingToMeta[order],
                            });
                          }}
                          options={[{
                            label: t('social:feed_order_recent_messages'),
                            value: EFeedSortTypes.LAST_CREATED,
                            icon: 'format_list_numbered',
                          }, {
                            label: t('social:feed_order_last_activity'),
                            value: EFeedSortTypes.LAST_ACTIVITY,
                            icon: 'arrow_circle_up',
                          }]}
                        />

                        <Feed
                          listRef={this.listRef}
                          items={feed}
                          data={{
                            useCursor: true,
                            onFetch: this.props.fetchTimeline,
                            new: newFeedItems,
                            filter: {
                              type: channel.type,
                              id: channel.id,
                              sort_by: feedOrder,
                            },
                            onPostFetch: this.props.receiveMessages,
                          }}
                          rowProps={{
                            // @ts-expect-error
                            onOpenExchange: (id) => history.push(`/networks/${network.id}/flexchange/exchanges/${id}`),
                            shouldHideParent: channel ? channel.type !== ETimelineTypes.FEED : false,
                          }}
                          header={isBirthdayToday && (
                            // @ts-expect-error
                            <FeedBirthdayCard loggedUser={loggedUser} message={birthdayMessageToday} />
                          )}
                        />
                      </>
                    </TrackComponent>
                  </Route>
                </Switch>
              </Route>
            </Switch>
          </Container.Column>

          <Container.SideBar className="hidden-sd hidden-md">
            <MembersWidget
              channel={channel}
              onEnter={() => AnalyticsService.track(EEventNames.VIEWED_GROUP_MEMBERS_LIST)}
            />
            <BirthdaysWidget
              loggedUser={loggedUser}
              birthdays={birthdaysToday}
              onView={this.handleViewBirthdays}
            />
            <EventsWidget
              events={events}
              onView={this.handleViewEvents}
              onOpen={this.handleOpenEvent}
            />
            {shortcuts.length > 0 && (
              <ShortcutsWidget
                shortcuts={shortcuts}
                onView={this.handleViewShortcuts}
              />
            )}

            <div className="balloon">
              <h5 className="balloon__title">
                <span><Trans i18nKey="social:app_feedback_title" /></span>
              </h5>
              <Trans i18nKey="social:app_feedback_description" />
              <br />
              <br />
              <Button type="primary" size="large" onClick={this.handleShowIntercom}>
                <Trans i18nKey="social:send_app_feedback" />
              </Button>
            </div>
          </Container.SideBar>
        </Container.Content>
      </Container>
    );
  }
}

export default withTranslation()(connector(Social));

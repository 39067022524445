import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { capitalize } from 'lodash';

import AsyncList from '@common/components/list/async';
import Profile from '@common/components/profile';
import { Button } from '@common/components/button';
import type { LoggedUser, User, History } from '@common/types/objects';

export type Props = {
  history: History,
  item: {
    date: string,
    users: Array<User>,
  },
  loggedUser: LoggedUser,
  push: (path: string) => void,
  fetchBirthdays: () => Promise<void>,
};

const dateFormats = {
  sameDay: '[Vandaag] · D MMM',
  nextDay: '[Morgen] · D MMM',
  nextDays: 'DDD D MMM',
  nextWeek: 'dddd · D MMM',
  sameElse: 'D MMM',
};

const BirthdaysListDate = ({
  history, item, loggedUser,
}: Props): JSX.Element => {
  const date = moment(item.date);
  const isToday = date.isSame(moment(), 'date');

  const dateClassNames = ['BirthdaysList__Date'];
  if (isToday) dateClassNames.push('BirthdaysList__Date--today');

  return (
    <div className={dateClassNames.join(' ')}>
      <div className="BirthdaysList__Date__Section">
        {isToday && <img src="/static/images/ic-cake-96-px.png" alt="cake" />}
        {capitalize(date.calendar(undefined, dateFormats))}
        {date.year() !== moment().year() && ` ${date.year()}`}
      </div>
      {item.users.map((user) => (
        <div className="BirthdaysList__Date__User">
          <Profile size={30} user={user} />

          {user.id !== loggedUser.id && isToday && (
            <Button
              type="primary"
              size="small"
              onClick={() => history.push(`/conversations/users/${user.id}`)}
            >
              <Trans i18nKey="network:birthdays_list_congratulate_button" />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

// @ts-expect-error
const BirthdaysList = (props) => (
  <AsyncList
    containerClassName="BirthdaysList"
    data={{
      useCursor: undefined,
      onFetch: props.fetchBirthdays,
    }}
    renderRow={BirthdaysListDate}
    rowProps={props}
  />
);

const mapDispatchToProps = {
  fetchBirthdays: require('../../actions/fetch-birthdays').default,
};

export default withRouter(connect(null, mapDispatchToProps)(BirthdaysList));
